/* :global(#about-hero) {

    &>div {
        & header {
            justify-content: start;
            margin: 40px 200px;
            display: inline-block;

            @media(max-width:768px) {
                margin: 0;
            }

            & h1 {
                font-size: 58px;
                max-width: 300px;
                text-align: left;
            }

            & p {
                max-width: 271px;
                text-align: left;
                line-height: 26px;
                color: #bf5c3a;
            }
        }

    }
} */
:global(#about-hero) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0;

            @media (max-width:768px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    order: 2;

                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    order: 1;

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 900 77px/90px Roc Grotesk;
                        max-width: 375px;
                        text-align: center;

                        @media(max-width:768px) {
                            font-size: 60px;
                            line-height: 60px;
                            max-width: 100%;
                        }
                    }

                    & p {
                        font: normal normal 900 24px/20px Roc Grotesk;
                        max-width: 388px;
                        text-align: center;
                        line-height: 28px;
                        color: #bf5c3a;
                    }
                }
            }
        }
    }
}

:global(#short-for-love) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0px;

            @media (max-width:1024px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    padding: 60px;

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 500 44px/56px Roc Grotesk;
                        white-space-collapse: break-spaces;

                        @media(max-width:768px) {
                            font: normal normal 500 30px/56px Roc Grotesk;
                        }
                    }

                    & p {
                        font: normal normal 400 22px/32px Roc Grotesk;
                        text-align: left;
                        max-width: 70%;

                        @media(max-width:1100px) {
                            max-width: 100%;
                        }

                        @media(max-width:768px) {
                            font-size: 16px;
                        }

                        &:nth-of-type(odd) {
                            font: normal normal 500 30px/22px Roc Grotesk;
                            color: #000;
                            padding: 0;
                            margin: 0;

                            @media(max-width:768px) {
                                font: normal normal 500 20px/24px Roc Grotesk;

                            }
                        }
                    }
                }
            }
        }
    }
}

:global(#why-did-we) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0px;

            @media (max-width:1024px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    @media(min-width: 1024px) {
                        order: 2;
                    }

                    &>div>div {
                        height: 500px;

                        @media(max-width:920px) {
                            height: 400px;
                        }

                        & img {
                            border-radius: 0;
                            height: 500px;
                            object-fit: contain;
                        }
                    }
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    padding: 60px;

                    @media(min-width: 1024px) {
                        order: 1;
                    }

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 500 44px/56px Roc Grotesk;
                        white-space-collapse: break-spaces;

                        @media(max-width:768px) {
                            font: normal normal 500 30px/56px Roc Grotesk;
                        }
                    }

                    & p {
                        font: normal normal 400 22px/32px Roc Grotesk;
                        text-align: left;
                        max-width: 70%;

                        @media(max-width:1100px) {
                            max-width: 100%;
                        }

                        @media(max-width:768px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

:global(#we-do-local) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0px;

            @media (max-width:1024px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    padding: 60px;

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 500 44px/56px Roc Grotesk;
                        white-space-collapse: break-spaces;

                        @media(max-width:768px) {
                            font: normal normal 500 30px/56px Roc Grotesk;
                        }
                    }

                    & p {
                        font: normal normal 400 22px/32px Roc Grotesk;
                        text-align: left;
                        max-width: 70%;

                        @media(max-width:1100px) {
                            max-width: 100%;
                        }

                        @media(max-width:768px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

:global(#lulocal-mission) {
    &>div {
        &>div {
            max-width: 1024px;

            &>div {
                & p {
                    color: #ffffff;
                    text-align: center;
                    font-size: 26px;
                    font-weight: 400;

                    & strong {
                        font-size: 30px;
                        font-weight: 600;
                    }
                }

                & a {
                    width: 150px;
                    margin: 30px auto;
                    background-color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                }
            }
        }
    }
}

:global(#we-know-you-love-local) {
    & h1 {
        max-width: 354px;
        text-align: center;
        margin: 0 auto;
        font: normal normal 900 44px/50px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    & p {
        max-width: 354px;
        text-align: center;
        margin: 6px auto 12px;
        text-align: center;
        font: normal normal normal 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    & a {
        margin: 0 auto;
        display: block;
        width: 200px;
        background: unset;
        box-shadow: unset;
        text-decoration: underline;
        font: normal normal bold 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media(max-width:768px) {
            width: fit-content;
        }
    }

    &>div {
        padding: 0 0 39px;

        &>div {
            gap: 18px;
            grid-template-columns: unset;
            max-width: 1875px;
            grid-template-areas:
                'left1 right1 right1 right1 right1 right1 '
                'left2 right1 right1 right1 right1 right1 ';

            @media (max-width: 1023px) {
                padding: 0 22px;
                grid-template-areas:
                    'left1 left2 '
                    'right1 right1';
            }

            @media (max-width: 767px) {
                grid-template-areas:
                    'left1'
                    'left2'
                    'right1';
            }

            &>div {}
        }
    }

    & :global(#we-know-you-love-merchants) {
        grid-area: left1;
        background: #c4d4d2 0% 0% no-repeat padding-box;
        padding: 130px 0 100px;
        max-width: 597px;

        @media (max-width: 1500px) {
            padding: 90px 0 80px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    & :global(#we-know-you-love-shoppers) {
        grid-area: left2;
        background: #e1b23d 0% 0% no-repeat padding-box;
        padding: 130px 0 100px;
        max-width: 597px;

        @media (max-width: 1260px) {
            padding: 90px 0 80px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    & :global(#we-know-you-love-image) {
        grid-area: right1;
        position: relative;

        & img {
            border-radius: 0;
        }

        &>div {
            height: 100%;

            &:nth-child(2) {
                position: absolute;
                bottom: -23%;
                left: 92px;
                max-width: 526px;

                @media (max-width: 1260px) {
                    max-width: 400px;
                    left: 32px;
                    bottom: -23%;
                }

                @media (max-width: 767px) {
                    max-width: 400px;
                    left: 32px;
                    bottom: -63%;
                }

                @media (max-width: 580px) {
                    left: 25px;
                    bottom: -40%;
                }

                @media (max-width:400px) {
                    bottom: -35%;
                }

                & h3 {
                    text-align: left;
                    font: normal normal 700 80px/90px Roc Grotesk;
                    letter-spacing: 0px;
                    color: #ffffff;
                    max-width: 500px;

                    @media (max-width: 1260px) {
                        font-size: 50px;
                        line-height: 60px;
                    }

                    @media (max-width: 767px) {
                        font-size: 26px;
                        line-height: 44px;
                        max-width: 300px;
                    }
                }
            }

            & a {
                margin: 0;
                color: #fff;
                background-color: #bf5c3a;
                text-decoration: none;
                width: fit-content;

                @media(max-width:580px) {
                    font-size: 16px;
                    width: fit-content;
                }
            }
        }
    }
}