:global(#rate-plans-hero) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0;

            @media (max-width:768px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 900 76px/86px Roc Grotesk;
                        max-width: 464px;
                        text-align: center;

                        @media (max-width:500px) {
                            max-width: 350px;
                            font-size: 40px;
                            line-height: normal;
                        }
                    }

                    & p {
                        font: normal normal 700 24px/30px Roc Grotesk;
                        color: #bf5c3a;
                        text-align: center;
                        max-width: 470px;
                    }

                    & a {
                        margin: 20px auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: fit-content;
                    }
                }
            }
        }
    }
}



:global(#lulocal-rate-plans) {


    &>div {
        padding-bottom: 0;

        &>div {
            max-width: 1170px;
            gap: 30px;
            padding: 0;

            @media(max-width:768px) {
                padding: 0;
            }

            &>div {
                &:nth-child(1) {
                    background-color: #e1b23d;
                    padding: 34px 0;

                    &>div {
                        & h3 {
                            text-align: center;
                            font: normal normal 900 40px/40px Roc Grotesk;
                            color: #000;

                            @media (max-width:768px) {
                                font-size: 30px;
                            }
                        }

                        & p {
                            &:nth-of-type(1) {
                                text-align: center;
                                max-width: 100%;
                                font-size: 150px;
                                color: #000;
                                margin-top: 0;
                                line-height: normal;
                                font-family: 'Inter';
                                font-weight: 900;

                                @media (max-width:768px) {
                                    font-size: 70px;
                                }

                                & strong {
                                    font-size: 40px;
                                    margin-left: -30px;

                                    @media (max-width:768px) {
                                        font-size: 30px;
                                        margin-left: 0;
                                    }
                                }
                            }

                            &:nth-of-type(2) {
                                background-color: #ffedbfa6;
                                text-align: center;
                                max-width: 100%;
                                padding: 10px 0;
                                font-size: 24px;
                                font-weight: 400;
                                color: #000;
                                white-space-collapse: break-spaces;


                                @media (max-width:768px) {
                                    font-size: 24px;
                                    padding: 18px;
                                }
                            }

                            &:nth-of-type(3) {
                                background-color: #000;
                                text-align: center;
                                max-width: 100%;
                                padding: 10px 0;
                                font-size: 30px;
                                font-weight: 700;
                                color: #fff;
                                margin-top: 0;

                                @media (max-width:768px) {
                                    font-size: 24px;
                                    padding: 18px;
                                }
                            }

                            &:nth-of-type(4) {
                                text-align: left;
                                max-width: 100%;
                                padding: 10px 20px;
                                font-size: 18px;
                                font-weight: 400;
                                color: #000;
                                margin-top: 28px;

                                @media (max-width:768px) {
                                    font-size: 18px;
                                    padding: 18px;
                                }
                            }
                        }

                        & ul {
                            /* padding-inline-start: 60px;

                        @media (max-width:768px) {
                            padding-inline-start: 40px;
                        } */

                            & li {
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 28px;
                                color: #000;
                                padding: 0 10px 10px 10px;

                                @media (max-width:768px) {
                                    font-size: 18px;
                                    line-height: 28px;
                                    padding-bottom: 8px;
                                    padding-right: 10px;
                                }

                            }
                        }

                        & a {
                            width: 230px;
                            margin: 40px auto;
                            font-size: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        }
                    }
                }
            }

            &:nth-child(2) {
                background-color: #e1b23d;
                padding: 34px 0;

                &>div {
                    & h3 {
                        text-align: center;
                        font: normal normal 900 40px/40px Roc Grotesk;
                        color: #fff;

                        @media (max-width:768px) {
                            font-size: 30px;
                        }
                    }
                }

                &>div {
                    & h3 {
                        text-align: center;
                        font: normal normal 900 40px/40px Roc Grotesk;
                        color: #fff;

                        @media (max-width:768px) {
                            font-size: 30px;
                        }
                    }

                    & p {
                        &:nth-of-type(1) {
                            text-align: center;
                            max-width: 100%;
                            font-size: 100px;
                            color: #000;
                            margin-top: 0;
                            line-height: normal;
                            font-family: 'Inter';
                            font-weight: 900;

                            @media (max-width:768px) {
                                font-size: 60px;
                            }

                            & strong {
                                font-size: 35px;
                                margin-left: -30px;

                                @media (max-width:768px) {
                                    font-size: 30px;
                                    margin-left: 0;
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            background-color: #ffedbfa6;
                            text-align: center;
                            max-width: 100%;
                            padding: 10px 0;
                            font-size: 24px;
                            font-weight: 400;
                            color: #000;
                            white-space-collapse: break-spaces;


                            @media (max-width:768px) {
                                font-size: 24px;
                                padding: 18px;
                            }
                        }

                        &:nth-of-type(3) {
                            background-color: #000;
                            text-align: center;
                            max-width: 100%;
                            padding: 10px 0;
                            font-size: 30px;
                            font-weight: 700;
                            color: #fff;
                            margin-top: 0;

                            @media (max-width:768px) {
                                font-size: 24px;
                                padding: 18px;
                            }
                        }

                        &:nth-of-type(4) {
                            text-align: center;
                            max-width: 100%;
                            padding: 10px 20px;
                            font-size: 18px;
                            font-weight: 400;
                            color: #000;
                            margin-top: 0;

                            @media (max-width:768px) {
                                font-size: 18px;
                                padding: 18px;
                            }
                        }
                    }

                    & ul {
                        /* padding-inline-start: 60px;

                            @media (max-width:768px) {
                                padding-inline-start: 40px;
                            } */

                        & li {
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 28px;
                            color: #000;
                            padding: 0 10px 10px 10px;

                            @media (max-width:768px) {
                                font-size: 18px;
                                line-height: 28px;
                                padding-bottom: 8px;
                                padding-right: 10px;
                            }

                        }
                    }

                    & a {
                        width: 294px;
                        margin: 40px auto;
                        font-size: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }

            &:nth-child(3) {
                background-color: #e1b23d;
                padding: 0 32px;

                &>div {
                    padding: 40px;

                    & p {
                        text-align: center;
                        max-width: 80%;
                        margin: 0 auto;
                        color: #000;
                        font-weight: 400;
                    }

                    & a {
                        width: 300px;
                        margin: 40px auto;
                        font-size: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media(max-width:560px) {
                            width: 200px;
                            text-align: center;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

:global(#additional-terms) {
    &>div {
        padding: 0;

        &>div {
            max-width: 1170px;
            margin: 0 auto;
            background-color: #ffedbfa6;
            padding: 34px;

            &>div>div {
                & h3 {
                    text-align: left;
                    max-width: 80%;
                    margin: 0 auto;
                    color: #000;
                    font-weight: 400;
                }

                & ul {
                    & li {
                        /* text-align: center; */
                        max-width: 84%;
                        margin: 0 auto;
                        color: #000;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

:global(#here-help-section) {
    &>div {
        background-color: #e3e3e396;
        max-width: 1170px;
        margin: 0 auto;
        padding: 34px;

        & header {
            & p {
                color: #000;
                font-weight: 400;
            }
        }
    }
}

:global(#lulocal-rate-plans-block) {
    & h1 {
        max-width: 354px;
        text-align: center;
        margin: 0 auto;
        font: normal normal 700 44px/50px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    & p {
        max-width: 354px;
        text-align: center;
        margin: 6px auto 12px;
        text-align: center;
        font: normal normal normal 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    & a {
        margin: 0 auto;
        display: block;
        width: 200px;
        background: unset;
        box-shadow: unset;
        text-decoration: underline;
        font: normal normal bold 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media(max-width:768px) {
            width: fit-content;
        }
    }

    &>div {
        padding: 39px 0 39px;

        &>div {
            gap: 18px;
            grid-template-columns: unset;
            max-width: 1875px;
            grid-template-areas:
                'left1 right1 right1 right1 right1 right1 '
                'left2 right1 right1 right1 right1 right1 ';

            @media (max-width: 1023px) {
                padding: 0 22px;
                grid-template-areas:
                    'left1 left2 '
                    'right1 right1';
            }

            @media (max-width: 767px) {
                grid-template-areas:
                    'left1'
                    'left2'
                    'right1';
            }

            &>div {}
        }
    }

    & :global(#lulocal-rate-plans-merchants) {
        grid-area: left1;
        background: #c4d4d2 0% 0% no-repeat padding-box;
        padding: 130px 0 100px;
        max-width: 597px;

        @media (max-width: 1500px) {
            padding: 90px 0 80px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    & :global(#lulocal-rate-plans-privacy) {
        grid-area: left2;
        background: #e1b23d 0% 0% no-repeat padding-box;
        padding: 130px 0 100px;
        max-width: 597px;

        @media (max-width: 1260px) {
            padding: 90px 0 80px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    & :global(#lulocal-rate-plans-image) {
        grid-area: right1;
        position: relative;

        & img {
            border-radius: 0;
        }

        &>div {
            height: 100%;

            &:nth-child(2) {
                position: absolute;
                bottom: -72%;
                right: 0;
                max-width: 100%;


                @media (max-width: 1260px) {
                    /* max-width: 300px; */
                    right: 0;
                    bottom: -72%;
                }

                /* 
                @media (max-width:920px) {
                    right: 0;
                    bottom: -38%;
                } */


                @media (max-width:400px) {
                    bottom: -70%;
                }

                & h3 {
                    text-align: center;
                    font: normal normal 700 56px/90px Roc Grotesk;
                    letter-spacing: 0px;
                    color: #ffffff;
                    max-width: 100%;

                    @media(max-width:1600px) {
                        color: #fff;
                        font: normal normal 700 35px/58px Roc Grotesk;
                        letter-spacing: 0;
                        max-width: 100%;
                        text-align: center;
                    }

                    /* @media (max-width: 1260px) {
                        font-size: 50px;
                        line-height: 60px;
                    } */

                    @media (max-width: 767px) {
                        font-size: 26px;
                        line-height: 44px;
                    }

                    @media (max-width: 460px) {
                        font-size: 14px;
                        line-height: normal;
                    }
                }
            }

            & a {
                margin: 0 auto;
                color: #fff;
                background-color: #bf5c3a;
                text-decoration: none;
                width: fit-content;

                @media(max-width:580px) {
                    font-size: 12px;
                    width: fit-content;
                    padding: 4px 8px;
                    line-height: 16px;
                    margin-top: 6px;
                }
            }
        }
    }
}