.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.shopSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  @media(max-width:500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & .shopBox {
    background: #e1b23d !important;
    padding: 50px 20px;
    text-decoration: none !important;

    & .shopHeading {
      font-size: 16px;
      font-weight: 400;
    }

    & .shopTitle {
      font-size: 32px;
      color: #000;
    }
  }
}