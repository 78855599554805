.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
.celebrateFinds{
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  @media(min-width: 768px){
    /* justify-content: center; */
  }
}
.productListingCard {
  display: grid;
  overflow-x: auto;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  &>div{
    padding: 0 !important;
  }
/* width */
&::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


/* Track */
&::-webkit-scrollbar-track {
  background: white;
}


/* Handle */
&::-webkit-scrollbar-thumb {
  background: #bf5c3a;
    border-radius: 5px;
}


/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #bf5c3a;
}

  &>div {
    width: 100%;
    min-width: 333px;
    display: inline-block;
    @media (max-width: 1023px) {
      min-width: 200px;
    }
  }
}
.shopAll{
  text-align: right;
  font: normal normal bold 24px/30px Roc Grotesk;
letter-spacing: 0px;
color: #BF5C3A;
margin-top: 36px;
@media (min-width: 1700px) {
  padding-right: 30px;
}
@media (max-width: 767px) {
  font: normal normal bold 18px/22px Roc Grotesk;
}
& svg{
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
  }
}
}