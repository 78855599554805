body {
    overflow-x: hidden;
}

:global(#shopping-simplified) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0;

            @media (max-width:768px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 900 76px/86px Roc Grotesk;
                        max-width: 464px;
                        text-align: center;

                        @media (max-width:500px) {
                            max-width: 350px;
                            font-size: 40px;
                            line-height: normal;
                        }
                    }

                    & p {
                        font: normal normal 900 24px/30px Roc Grotesk;
                        color: #bf5c3a;
                        text-align: center;
                    }
                }
            }
        }
    }
}

:global(#simplified-description) {
    &>div {
        &>div {
            max-width: 1260px;
            display: flex;
            align-items: center;
            justify-content: center;

            &>div {
                &>div {
                    text-align: center;

                    & p {
                        font-weight: 400;
                        padding: 30px 0;
                        font-size: 20px;

                        @media(max-width:768px) {
                            font-size: 16px;
                        }
                    }


                    &>div {
                        &:first-child {
                            &>div {
                                padding-bottom: 20% !important;

                                @media(max-width:768px) {
                                    padding-bottom: 40% !important;
                                }

                                & img {
                                    width: 150px;
                                    height: 150px;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

:global(#shop-small) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0px;

            @media (max-width:1024px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    /* display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto; */
                    padding: 60px;

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 500 44px/56px Roc Grotesk;
                        white-space-collapse: break-spaces;

                        @media(max-width:768px) {
                            font: normal normal 500 30px/56px Roc Grotesk;
                        }
                    }

                    & p {
                        font: normal normal 400 22px/32px Roc Grotesk;
                        text-align: left;
                        max-width: 70%;

                        @media(max-width:1100px) {
                            max-width: 100%;
                        }

                        @media(max-width:768px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

:global(#loving-local) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0px;

            @media (max-width:1024px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    @media(min-width: 1024px) {
                        order: 2;
                    }

                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    padding: 60px;

                    @media(min-width: 1024px) {
                        order: 1;
                    }

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 500 44px/56px Roc Grotesk;
                        white-space-collapse: break-spaces;

                        @media(max-width:768px) {
                            font: normal normal 500 30px/56px Roc Grotesk;
                        }
                    }

                    & p {
                        font: normal normal 400 22px/32px Roc Grotesk;
                        text-align: left;
                        max-width: 70%;

                        @media(max-width:1100px) {
                            max-width: 100%;
                        }

                        @media(max-width:768px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

/* :global(#get-your-scroll-on-second) {
    & h1 {
        max-width: 354px;
        text-align: center;
        margin: 0 auto;
        font: normal normal 900 44px/50px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    & p {
        max-width: 354px;
        text-align: center;
        margin: 6px auto 12px;
        text-align: center;
        font: normal normal normal 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    & a {
        margin: 0 auto;
        display: block;
        width: fit-content;
        background: unset;
        box-shadow: unset;
        text-decoration: underline;
        font: normal normal bold 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;
    }

    &>div {
        padding: 39px 0;

        &>div {
            gap: 18px;
            grid-template-columns: unset;
            max-width: 1875px;
            grid-template-areas:
                'right1 right1 right1 right1 right1 left1 '
                'right1 right1 right1 right1 right1 left2';

            @media (max-width: 1023px) {
                padding: 0 22px;
                grid-template-areas:
                    'left1 left2 '
                    'right1 right1';
            }

            @media (max-width: 767px) {
                grid-template-areas:
                    'left1'
                    'left2'
                    'right1';
            }

            &>div {}
        }
    }

    & :global(#get-your-signup) {
        grid-area: left1;
        background: #c4d4d2 0% 0% no-repeat padding-box;
        padding: 130px 0 100px;
        max-width: 597px;

        @media (max-width: 1500px) {
            padding: 90px 0 80px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    & :global(#get-your-question) {
        grid-area: left2;
        background: #e1b23d 0% 0% no-repeat padding-box;
        padding: 130px 0 100px;
        max-width: 597px;

        @media (max-width: 1260px) {
            padding: 90px 0 80px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    
} */
:global(#get-your-scroll-on-second) {
    &>div {
        display: flex;
        padding: 10px 24px 36px;
        gap: 16px;

        @media (max-width: 1023px) {
            flex-direction: column;
            padding: 0 20px 36px;
        }

        & header {
            & a {
                background-color: #bf5c3a;
                color: #ffffff;
                width: fit-content;
                z-index: 1;
                text-decoration: none;
            }
        }

        &>div {
            width: 35%;
            padding: 0;
            gap: 15px;
            margin: 0;

            @media (max-width: 1023px) {
                width: 100%;
            }
        }
    }

    & h2 {
        font: normal normal 900 44px/50px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 767px) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    & p {
        font: normal normal normal 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1023px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    & a {
        text-align: center;
        text-decoration: underline;
        font: normal normal bold 20px/32px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;
        background: unset;
        box-shadow: unset;
    }

    & :global(#get-your-signup) {
        background: #c4d4d2;
        text-align: center;
        padding: 120px 0 110px;

        @media (max-width: 1500px) {
            padding: 60px 0;
        }
    }

    & :global(#get-your-question) {
        background: #e1b23d 0% 0% no-repeat padding-box;
        text-align: center;
        padding: 120px 0 110px;

        @media (max-width: 1500px) {
            padding: 60px 0;
        }
    }

    & header {
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 90px 103px;
        max-width: 100%;

        @media (max-width: 1023px) {
            width: 100%;
        }

        @media (max-width: 1023px) {
            height: 400px;
            padding: 48px;
        }

        @media (max-width: 767px) {
            height: 300px;
            padding: 32px;
        }

        & video {
            border-radius: unset;
        }

        & h2 {
            font: normal normal 900 80px/90px Roc Grotesk;
            letter-spacing: 0px;
            color: #ffffff;
            text-align: left;
            max-width: 570px;
            z-index: 1;
            font-weight: 700;

            @media (max-width: 1023px) {
                font-size: 48px;
                line-height: 56px;
            }

            @media (max-width: 767px) {
                font-size: 32px;
                line-height: 38px;
            }
        }
    }
}