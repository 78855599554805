:global(#selling-locally-simplified) {
    &>div {
        padding: 0;

        &>div {
            padding: 0 !important;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 100%;
            gap: 0;

            @media (max-width:768px) {
                grid-template-columns: 100%;
            }

            &>div {
                &:nth-child(1) {
                    order: 2;

                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    order: 1;

                    @media (max-width:768px) {
                        padding: 40px;
                    }

                    & h3 {
                        font: normal normal 900 76px/86px Roc Grotesk;
                        max-width: 464px;
                        text-align: center;

                        @media (max-width:500px) {
                            max-width: 350px;
                            font-size: 40px;
                            line-height: normal;
                        }
                    }

                    & p {
                        font: normal normal 700 24px/30px Roc Grotesk;
                        color: #bf5c3a;
                        text-align: center;
                    }
                }
            }
        }
    }
}

:global(#expanding-your-audience) {
    &>div {
        &>div {
            max-width: 1260px;
            display: flex;
            align-items: center;
            justify-content: center;

            &>div {
                &>div {
                    text-align: center;

                    & p {
                        font-weight: 400;
                        padding: 0;
                        font-size: 20px;

                        @media(max-width:768px) {
                            font-size: 16px;
                        }
                    }

                    &>div {
                        &:first-child {
                            &>div {
                                padding-bottom: 20% !important;

                                @media(max-width:768px) {
                                    padding-bottom: 40% !important;
                                }

                                & img {
                                    width: 150px;
                                    height: 150px;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

:global(#merchant-benefits) {

    &>div {
        &>div {
            max-width: 100%;

            &>div {
                &:nth-child(1) {
                    & img {
                        border-radius: 0;
                    }
                }

                &:nth-child(2) {
                    & p {
                        font: normal normal 400 20px/20px Roc Grotesk;
                    }

                    & ul {
                        & li {
                            font: normal normal 400 20px/30px Roc Grotesk;
                        }
                    }
                }
            }
        }
    }
}

:global(#all-the-perks) {
    & h1 {
        max-width: 500px;
        text-align: center;
        margin: 0 auto;
        font: normal normal 900 44px/50px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    & p {
        max-width: 500px;
        text-align: center;
        margin: 6px auto 12px;
        text-align: center;
        font: normal normal normal 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;

        @media (max-width: 1320px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    & a {
        margin: 0 auto;
        display: block;
        width: 200px;
        background: unset;
        box-shadow: unset;
        text-decoration: underline;
        font: normal normal bold 20px/30px Roc Grotesk;
        letter-spacing: 0px;
        color: #3a3d3a;
    }

    &>div {
        padding: 39px 0;

        &>div {
            gap: 18px;
            grid-template-columns: unset;
            max-width: 1875px;
            grid-template-areas:
                'left1 right1 right1 right1 right1 right1'
                'left2 right1 right1 right1 right1 right1';

            @media (max-width: 1023px) {
                padding: 0 22px;
                grid-template-areas:
                    'left1 left2 '
                    'right1 right1';
            }

            @media (max-width: 767px) {
                grid-template-areas:
                    'left1'
                    'left2'
                    'right1';
            }

            &>div {}
        }
    }

    & :global(#it-headache) {
        grid-area: left1;
        background: #c4d4d2 0% 0% no-repeat padding-box;
        padding: 42px 0 42px;
        /* max-width: 597px; */

        @media (max-width: 1500px) {
            padding: 42px 0 42px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
            padding: 24px;
        }
    }

    & :global(#local-beyond) {
        grid-area: left2;
        background: #e1b23d 0% 0% no-repeat padding-box;
        padding: 42px 0 42px;
        /* max-width: 597px; */

        @media (max-width: 1260px) {
            padding: 42px 0 42px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
            padding: 24px;
        }
    }

    & :global(#all-the-perks-block) {
        grid-area: right1;
        position: relative;

        & img {
            border-radius: 0;
        }

        &>div {
            height: 100%;

            &:nth-child(2) {
                position: absolute;
                bottom: -67%;
                left: 92px;
                max-width: 600px;

                @media (max-width: 1260px) {
                    max-width: 400px;
                    left: 32px;
                    bottom: -76%;
                }

                @media (max-width: 767px) {
                    max-width: 400px;
                    left: 32px;
                    bottom: -63%;
                }

                @media (max-width: 580px) {
                    left: 25px;
                    bottom: -56%;
                }

                @media(max-width: 425px) {
                    left: 25px;
                    bottom: -38%;
                }
            }
        }
    }
}