@font-face {
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: local('Roc Grotesk'),
    url('../../../../assets/Fonts/RocGrotesk/Fontspring-DEMO-rocgrotesk-black.otf') format('truetype');
}

@font-face {
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: 910;
  font-display: fallback;
  src: local('Roc Grotesk'),
    url('../../../../assets/Fonts/RocGrotesk/Fontspring-DEMO-rocgroteskwide-heavy.otf') format('truetype');
}

/* @font-face {
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: bold;
  font-display: fallback;
  src: local('Roc Grotesk'),
    url('../../../../assets/Fonts/RocGrotesk/Fontspring-DEMO-rocgrotesk-medium.otf')
      format('truetype');
}
@font-face {
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
  src: local('Roc Grotesk'),
    url('../../../../assets/Fonts/RocGrotesk/Fontspring-DEMO-rocgrotesk-regular.otf')
      format('truetype');
}
@font-face {
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('Roc Grotesk'),
    url('../../../../assets/Fonts/RocGrotesk/Fontspring-DEMO-rocgrotesk-bold.otf')
      format('truetype');
} */
@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff'),
    url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff'),
    url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-compressed';
  src: url('https://use.typekit.net/af/69af51/00000000000000007735b7bd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/69af51/00000000000000007735b7bd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/69af51/00000000000000007735b7bd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-compressed';
  src: url('https://use.typekit.net/af/404af9/00000000000000007735b7d5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/404af9/00000000000000007735b7d5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff'),
    url('https://use.typekit.net/af/404af9/00000000000000007735b7d5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-condensed';
  src: url('https://use.typekit.net/af/b47a88/00000000000000007735b7be/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/b47a88/00000000000000007735b7be/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/b47a88/00000000000000007735b7be/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-condensed';
  src: url('https://use.typekit.net/af/f1cda4/00000000000000007735b7c7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/f1cda4/00000000000000007735b7c7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff'),
    url('https://use.typekit.net/af/f1cda4/00000000000000007735b7c7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-extrawide';
  src: url('https://use.typekit.net/af/638c22/00000000000000007735b7cb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/638c22/00000000000000007735b7cb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/638c22/00000000000000007735b7cb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-extrawide';
  src: url('https://use.typekit.net/af/4f5493/00000000000000007735b7db/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/4f5493/00000000000000007735b7db/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff'),
    url('https://use.typekit.net/af/4f5493/00000000000000007735b7db/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-wide';
  src: url('https://use.typekit.net/af/bcc24a/00000000000000007735b7c2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/bcc24a/00000000000000007735b7c2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/bcc24a/00000000000000007735b7c2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roc Grotesk-wide';
  src: url('https://use.typekit.net/af/c9606e/00000000000000007735b7cd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/c9606e/00000000000000007735b7cd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff'),
    url('https://use.typekit.net/af/c9606e/00000000000000007735b7cd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

:global(#hero) {
  &>div {
    &:nth-child(2) {
      display: grid;
      grid-template-columns: 65% 35%;
      padding: 37px 22px 83px;
@media(max-width:1260px) {
  grid-template-columns: 50% 48%;
}
@media(max-width:1170px) {
  grid-template-columns: 50% 50%;
}
      @media (max-width: 1023px) {
        grid-template-columns: 100%;
      }

      &>div {
        padding: 0 0 0 22px;
        margin: 0;
        gap: 12px;

        @media (max-width: 1023px) {
          padding: 22px 0 0 0px;
        }

        & h1 {
          text-align: center;
          font: normal normal 900 44px/50px Roc Grotesk;
          letter-spacing: 0px;
          color: #3a3d3a;

          @media (max-width: 1320px) {
            font-size: 32px;
            line-height: 38px;
          }
        }

        & p {
          text-align: center;
          font: normal normal normal 20px/30px Roc Grotesk;
          letter-spacing: 0px;
          color: #3a3d3a;
          max-width: 328px;
          margin: auto;

          @media (max-width: 1320px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        & a {
          text-decoration: underline;
          font: normal normal bold 20px/30px Roc Grotesk;
          letter-spacing: 0px;
          color: #3a3d3a;
          background: transparent;
          box-shadow: none;
          text-align: center;
          display: block;
          margin-top: 0;
        }
      }

      & header {
        display: unset;
        background: slategray;
        padding: 108px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0;
        border-radius: 5px;
        max-width: 100%;

        @media (max-width: 1500px) {
          padding: 88px;
        }

        @media (max-width: 1260px) {
          padding: 48px;
        }

        @media (max-width: 767px) {
          padding: 32px;
        }

        & p {
          text-align: left;
          font: normal normal bold 32px/44px Roc Grotesk;
          letter-spacing: 0px;
          color: #ffffff;
          z-index: 1;
          max-width: 800px;

          @media (max-width: 1320px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        & a {
          background: #bf5c3a 0% 0% no-repeat padding-box;
          border-radius: 5px;
          max-width: 204px;
          height: 56px;
          font: normal normal bold 20px/36px Roc Grotesk;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;

          @media (max-width: 1500px) {
            max-width: 186px;
            height: 48px;
          }
        }
      }
    }
  }

  & h2 {
    text-align: left;
    font: normal normal 900 80px/90px Roc Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    z-index: 1;
    max-width: 570px;

    @media (max-width: 767px) {
      font-size: 48px;
      line-height: 55px;
    }
  }

  & :global(#sell) {
    background: #c4d4d2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 94px 10%;

    @media (max-width: 1500px) {
      padding: 70px 8%;
    }

    @media (max-width: 1260px) {
      padding: 60px 8%;
    }
  }

  & :global(#shop) {
    background: #e1b23d 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 94px 10%;

    @media (max-width: 1500px) {
      padding: 70px 8%;
    }

    @media (max-width: 1260px) {
      padding: 60px 8%;
    }
  }
}

:global(#background-video) {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 100%;
  z-index: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

:global(#celebrate-new-finds) {
  & header {
    &>div {
      &>div {
        max-width: calc(100vw - 64px);

        &>div {
          padding: 30px;

          @media (max-width: 1023px) {
            padding: 12px;
          }
        }
      }
    }
  }

  & h1 {
    text-align: center;
    font: normal normal 900 44px/50px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 1320px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & p {
    text-align: center;
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 0 auto;
    max-width: 750px;

    @media (max-width: 1320px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

:global(#the-easiest-way) {
  & h1 {
    text-align: left;
    font: normal normal 900 44px/50px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    max-width: 567px;

    @media (max-width: 1320px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & p {
    text-align: left;
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    max-width: 681px;
    margin-top: 20px;

    @media (max-width: 1320px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & a {
    background: #bf5c3a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    letter-spacing: 0px;
    color: #ffffff;
    height: 56px;
    font: normal normal bold 20px/36px Roc Grotesk;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    max-width: 204px;
    margin-bottom: 24px;

    @media (max-width: 1500px) {
      max-width: 186px;
      height: 48px;
    }
  }

  &>div {
    &:nth-child(2) {
      padding-bottom: 0;
      padding-top: 96px;

      @media (max-width: 1360px) {
        padding-top: 48px;
      }

      &>div {
        grid-template-columns: repeat(2, 1fr);
        max-width: 1690px;
        gap: 96px;

        @media (max-width: 1023px) {
          grid-template-columns: repeat(1, 1fr);
          margin-bottom: 64px;
          padding: 32px 22px;
        }

        @media (min-width: 1600px) {
          gap: 90px;
        }
      }
    }
  }

  & :global(#easiest-way-image) {
    order: 2;
  }

  & :global(#easiest-way-text) {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    order: 1;

    @media (max-width: 1023px) {
      max-width: 650px;
      margin: 0 auto;
    }

    &>div {
      &:nth-child(1) {
        height: 100%;
        width: 100%;
        max-height: 68px;
        max-width: 96px;
      }
    }
  }
}

:global(#shopping-local) {
  & h1 {
    text-align: left;
    font: normal normal 900 44px/50px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    max-width: 650px;

    @media (max-width: 1320px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & p {
    text-align: left;
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    max-width: 680px;
    margin-top: 20px;

    @media (max-width: 1320px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & a {
    background: #bf5c3a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font: normal normal bold 20px/36px Roc Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    max-width: 223px;
    padding: 0;
    margin-bottom: 24px;

    @media (max-width: 1500px) {
      max-width: 210px;
      height: 48px;
    }
  }

  background: #ffffff;

  &>div {
    padding-bottom: 0;
    padding-top: 80px;

    @media (max-width: 1360px) {
      padding-top: 36px;
    }

    &>div {
      grid-template-columns: repeat(2, 1fr);
      max-width: 1690px;

      @media (min-width: 1780px) {
        padding: 0;
        height: 800px;
      }

      @media (max-width: 1023px) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 64px;
        padding: 0 22px;
      }
    }
  }

  & :global(#shopping-local-image) {
    align-items: flex-end;

    @media (max-width: 1360px) {
      align-items: center;
    }

    & img {
      display: none;
    }
  }

  & :global(#shopping-local-text) {
    flex-direction: column;
    max-width: 650px;
    margin-left: auto;
    gap: 0;
    align-items: flex-start;
    order: 2;

    @media (max-width: 1440px) {
      padding: 0 0 24px;
    }

    & img {
      object-fit: contain;
    }

    &>div {
      &:nth-child(1) {
        height: 100%;
        width: 100%;
        max-height: 130px;
        max-width: 130px;
      }

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }

  & :global(#shopping-local-image) {
    &>div>div>div {
      padding-bottom: 87.4% !important;
    }

    &>div>div>div>div {
      background-image: url('../../../../assets/LULO-Home-Shoppers.gif');
      background-size: 100%;
    }
  }
}

:global(#keep-the-good-times-rolling) {
  & h1 {
    max-width: 354px;
    text-align: center;
    margin: 0 auto;
    font: normal normal 900 44px/50px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 1320px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & p {
    max-width: 354px;
    text-align: center;
    margin: 6px auto 12px;
    text-align: center;
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 1320px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & a {
    margin: 0 auto;
    display: block;
    width: 200px;
    background: unset;
    box-shadow: unset;
    text-decoration: underline;
    font: normal normal bold 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
  }

  &>div {
    padding: 0 0 39px;

    &>div {
      gap: 18px;
      grid-template-columns: unset;
      max-width: 1875px;
      grid-template-areas:
        'left1 right1 right1 right1 right1 right1 '
        'left2 right1 right1 right1 right1 right1 ';

      @media (max-width: 1023px) {
        padding: 0 22px;
        grid-template-areas:
          'left1 left2 '
          'right1 right1';
      }

      @media (max-width: 767px) {
        grid-template-areas:
          'left1'
          'left2'
          'right1';
      }

      &>div {}
    }
  }

  & :global(#intrigued) {
    grid-area: left1;
    background: #c4d4d2 0% 0% no-repeat padding-box;
    padding: 130px 0 100px;
    max-width: 597px;

    @media (max-width: 1500px) {
      padding: 90px 0 80px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  & :global(#got-questions) {
    grid-area: left2;
    background: #e1b23d 0% 0% no-repeat padding-box;
    padding: 130px 0 100px;
    max-width: 597px;

    @media (max-width: 1260px) {
      padding: 90px 0 80px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  & :global(#keep-the-good-times-rolling-block) {
    grid-area: right1;
    position: relative;

    &>div {
      height: 100%;

      &:nth-child(2) {
        position: absolute;
        bottom: -67%;
        left: 92px;
        max-width: 600px;

        @media (max-width: 1260px) {
          max-width: 400px;
          left: 32px;
          bottom: -76%;
        }

        @media (max-width: 767px) {
          max-width: 400px;
          left: 32px;
          bottom: -63%;
        }

        @media (max-width: 580px) {
          left: 25px;
          bottom: -56%;
        }

        & h3 {
          text-align: left;
          font: normal normal 900 80px/90px Roc Grotesk;
          letter-spacing: 0px;
          color: #ffffff;
          max-width: 720px;

          @media (max-width: 1260px) {
            font-size: 50px;
            line-height: 60px;
          }

          @media (max-width: 767px) {
            font-size: 36px;
            line-height: 44px;
          }
        }
      }
    }
  }
}